$brand: 'ascentia';
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css");
@import url("https://use.typekit.net/uxt6rai.css");
@import "bootstrap/scss/bootstrap";
// Import Fonts
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";

// Your existing SCSS content
// Define brand-specific variables
$ascentia-brand-primary: rgb(99, 10, 22);
$ascentia-brand-secondary: #778596;
$ascentia-brand-tertiary: #8c9db2;
$ascentia-brand-highlight: #9d3d4a;
$ascentia-brand-highlight-secondary: #b56d77;
$ascentia-brand-header-font: "Droid Serif";
$ascentia-brand-body-font: "urbane-rounded", sans-serif;

$elevare-brand-primary: #0026c0;
$elevare-brand-secondary: #0026c0;
$elevare-brand-tertiary: #fff;
$elevare-brand-highlight: #db6265;
$elevare-brand-highlight-secondary: #f1b11a;
$elevare-brand-header-font: "Norwester";
$elevare-brand-body-font: "Montserrat";

// Define a mixin to set brand variables based on the selected brand
@mixin set-brand($brand) {
  @if $brand == "ascentia" {
    --brand-primary: #{$ascentia-brand-primary};
    --brand-secondary: #{$ascentia-brand-secondary};
    --brand-tertiary: #{$ascentia-brand-tertiary};
    --brand-highlight: #{$ascentia-brand-highlight};
    --brand-highlight-secondary: #{$ascentia-brand-highlight-secondary};
    --brand-header-font: #{$ascentia-brand-header-font};
    --brand-body-font: #{$ascentia-brand-body-font};
    --nav-logo-path: url("/src/images/aci-logo-black-text.png");
    --footer-logo-path: url("/src/images/aci-logo-white-text.png");
  } @else if $brand == "elevare" {
    --brand-primary: #{$elevare-brand-primary};
    --brand-secondary: #{$elevare-brand-secondary};
    --brand-tertiary: #{$elevare-brand-tertiary};
    --brand-highlight: #{$elevare-brand-highlight};
    --brand-highlight-secondary: #{$elevare-brand-highlight-secondary};
    --brand-header-font: #{$elevare-brand-header-font};
    --brand-body-font: #{$elevare-brand-body-font};
    --nav-logo-path: url("/src/images/eci-logo-dark-text.png");
    --footer-logo-path: url("/src/images/eci_logo_white_no_tagline.png");
  }
}

// Apply the brand-specific variables to CSS variables
:root {
  @include set-brand($brand);

  --bs-nav-tabs-link-active-bg: black !important;
  --bs-secondary-bg-rgb: rgb(255, 255, 255);
}

@font-face {
  font-family: droid-serif;
  src: url("/src/fonts/DroidSerif-Regular-webfont.woff");
}
@font-face {
  font-family: open-Sans;
  src: url("/src/fonts/OpenSans-Regular-webfont.woff");
}
@font-face {
  font-family: "Montserrat";
  src: url("/src/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Norwester";
  src: url("/src/fonts/norwester.otf");
}
$nav-tabs-active-bg: var(--brand-primary);
$font-family-sans-serif: var(--brand-body-font);
$accordion-icon-active-color: white;
$accordion-icon-color: white;

* {
  font-family: var(--brand-body-font);
  font-weight: 500;
  font-style: normal;
}

.nav-logo {
  content: var(--nav-logo-path);
  max-width: 300px;
}

.footer-logo {
  content: var(--footer-logo-path);
  max-width: 200px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--brand-header-font);
  font-weight: bold;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a {
  font-family: var(--brand-header-font);
  font-weight: bold;
}

h1 {
  color: var(--brand-primary);
}

h3,
h4 {
  color: var(--brand-secondary);
}

.btn-primary,
.btn-primary:active,
.accordion-button:not(.collapsed) {
  background-color: var(--brand-primary);
  color: var(--brand-tertiary) !important;
  // border-bottom: 2px solid var(--brand-secondary);
}
.btn-primary {
  color: white;
}

.has-primary-bg {
  background-color: var(--brand-primary);
  color: white;
}

.accordion-button::after {
  color: white;
}

.has-primary-bg > * {
  color: white;
}

hr {
  background-color: var(--brand-primary);
  border-color: var(--brand-primary);
  border-style: solid;
  border-width: 1.5px;
  opacity: 1;
}

h2 > a {
  text-decoration: none;
  color: var(--brand-tertiary);
}

a:hover {
  color: var(--brand-highlight);
}

.btn:hover,
.btn-primary:hover {
  background-color: var(--brand-tertiary);
  color: var(--bs-secondary-bg-rgb);
}

.nav-link.btn:hover {
  color: black;
}

#request-info:hover {
  color: black;
}
footer {
  background-color: black;
  color: white;
  text-decoration: none;
}
footer > p > a {
  color: white;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: unset;
}

.bg-highlight {
  background-color: var(--brand-highlight);
}

.bg-highlight-secondary {
  background-color: var(--brand-highlight-secondary);
}

main,
nav,
.bg-body-secondary,
header,
#top {
  background-color: rgba(255, 255, 255, 1) !important;
}

main {
  font-size: large;
}

body {
  background-color: black;
}

.program-card-img {
  min-height: 100%;
}
.card-footer {
  background-color: unset;
  border-top-style: none;
}

.text-left,
.text-left > *,
.text-left > * > * {
  text-align: left !important;
}
.text-right,
.text-right > *,
.text-right > * > * {
  text-align: right !important;
}

.text-primary {
  color: var(--brand-primary) !important;
}

nav {
  font-size: medium;
  align-items: end;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white !important;
  background-color: var(--brand-primary) !important;
}

.nav-tabs .nav-link:not(.active),
.nav-tabs .nav-item.show .nav-link {
  color: var(--brand-primary) !important;
  background-color: white !important;
  border: none;
  // border-color: rgba(white, 0);
}
.multiLevelList > li:not(ul > li) {
  font-weight: bold;
  color: var(--brand-primary);
}

.multiLevelList > li > ul > li {
  font-weight: 400;
  color: black;
}

#requirements > li {
  list-style-type: none;
  list-style-position: inside;
}

.age {
  list-style-image: url("/node_modules/@fortawesome/fontawesome-free/svgs/solid/clock.svg");
}

.education {
  list-style-image: url("/node_modules/@fortawesome/fontawesome-free/svgs/solid/graduation-cap.svg");
}

.list-upper-alpha {
  list-style-type: upper-alpha;
}

.list-lower-roman {
  list-style-type: lower-roman;
}

.headerImg {
  max-height: 300px;
  width: 99vw;
  object-fit: cover;
}

.bold {
  font-weight: bold;
}

main > div {
  min-width: 100%;
}

ul > li {
  line-height: 2em;
}

.detailsTable > tbody > tr > td {
  max-width: 25%;
}

.w-33 {
  width: calc(100% / 3);
}

.w-66 {
  width: calc((100% / 3) * 2);
}

.w-80 {
  width: 80%;
}

.red {
  color: red;
}

.w-10 {
  max-width: 1.5rem;
}

.pl-n2 {
  padding-left: 1.5rem;
}

.navbar-nav .btn-group .btn {
  vertical-align: middle;
}

.navbar-nav .btn-group .dropdown-toggle-split {
  padding-left: 0;
}

.navbar-nav .btn-group .dropdown-toggle::after {
  margin-left: 0.25rem;
}

#request-info {
  min-width: 100%;
}

.btn-nohighlight:hover {
  background-color: unset;
}

.cat-header {
  margin-left: -1em;
  margin-right: -1em;
}

@include media-breakpoint-up(lg) {
  .cat-header {
    margin-left: -2.5em;
    margin-right: -2.5em;
  }
}

.slide-image,
.slide-image > source,
.slide-image > img {
  //max height: 25vh;
  max-height: 25dvh;
}

.header-image {
  max-height: 225px;
  width: 99dvw;
}

.booking-page {
  border: 0;
  width: 100%;
  height: 100%;
}

/* Added CSS for responsive cards */
.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header,
.card-body,
.card-footer {
  flex: 1;
}

.card-group {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 992px) {
  .card-group {
    grid-template-columns: repeat(3, 1fr);
  }
  .card {
    flex-direction: column;
    justify-content: space-between;
  }
  .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.pill-button {
  border-radius: 50px;
  background-color: var(--brand-secondary);
  color: white !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.pill-button:hover {
  background-color: var(--brand-highlight-secondary);
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-title {
  margin-bottom: 1rem;
}

.object-fit-cover {
  object-fit: cover;
}
.accordion-header {
  background-color: var(--brand-primary);
  color: var(--brand-tertiary);
  //border: 2px solid var(--brand-highlight);
  padding: 10px;
  cursor: pointer;
  -webkit-transition:
    background-color 0.3s ease,
    color 0.3s ease;
  -moz-transition:
    background-color 0.3s ease,
    color 0.3s ease;
  -ms-transition:
    background-color 0.3s ease,
    color 0.3s ease;
  -o-transition:
    background-color 0.3s ease,
    color 0.3s ease;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.accordion-header:hover {
  background-color: var(--brand-highlight);
  color: var(--brand-tertiary);
}

.accordion-header:focus {
  background-color: var(--brand-highlight-secondary);
  color: var(--brand-tertiary);
  outline: none;
  box-shadow: 0 0 5px var(--brand-secondary);
}

.accordion-header.active {
  background-color: var(--brand-secondary);
  color: var(--brand-tertiary);
}

#map {
  width: 100%;
  height: 300px;
}

.text-center.px-3.py-2.mt-3.bg-body-secondary {
  color: var(--brand-primary);
}

#leadForm {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #f9f9f9;
}
#leadForm h2 {
  text-align: center;
  color: #333;
}
#leadForm label {
  margin-top: 10px;
  display: block;
  color: #666;
}
#leadForm input[type="text"],
#leadForm input[type="email"],
#leadForm input[type="tel"],
#leadForm select,
#leadForm textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
#leadForm input[type="submit"] {
  width: 100%;
  background-color: #0056b3;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}
#leadForm input[type="submit"]:hover {
  background-color: #004494;
}

.text-white:hover {
  color: black !important;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
}
